<template>
  <div class="section">
    <b-breadcrumb separator="has-succeeds-separator">
      <b-breadcrumb-item class="is-size-5 mb-5" active
        ><span class="is-underlined">{{
          $t('pages.profile.title')
        }}</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <div class="columns is-size-6 is-multiline">
      <div class="column is-4 has-text-weight-bold">
        {{ $t('fields.firstName') }}
      </div>
      <div class="column is-8">
        {{ user.verified ? user.firstName : $t('verification.underReview') }}
      </div>
      <div class="column is-4 has-text-weight-bold">
        {{ $t('fields.lastName') }}
      </div>
      <div class="column is-8">
        {{ user.verified ? user.lastName : $t('verification.underReview') }}
      </div>
      <div class="column is-4 has-text-weight-bold">
        {{ $t('fields.email') }}
      </div>
      <div class="column is-8">{{ user.email }}</div>
      <div class="column is-4 has-text-weight-bold">
        {{ $t('fields.phone') }}
      </div>
      <div class="column is-8">{{ user.phone }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped></style>
