<template>
  <div>
    <div v-if="user" class="section">
      <div class="columns">
        <div class="column is-one-third">
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <!-- avatar -->
            <b-image
              :src="require('@/assets/images/user/storm-trooper-avatar.png')"
              alt="Avatar"
              :rounded="true"
              style="width: 200px"
            ></b-image>
            <!-- name -->
            <div v-if="user" class="is-size-4 has-text-weight-bold mb-1">
              {{ `${user.firstName} ${user.lastName}` }}
            </div>
            <!-- status -->
            <div
              v-if="user && user.verified"
              class="is-flex is-justify-content-center is-align-items-center mb-5"
            >
              <span class="is-size-6">
                <b-icon
                  :icon="'check-circle'"
                  pack="far"
                  :type="'is-success'"
                  class="mr-2"
                >
                </b-icon>
              </span>
              <span class="is-size-6">
                {{ $t('labels.verified') }}
              </span>
            </div>
            <div v-else>
              <b-button
                type="is-primary"
                size="is-large"
                class="is-size-5"
                @click="handleVerify()"
                expanded
              >
                {{ $t('buttons.verifyIdentity') }}
              </b-button>
            </div>
            <!-- menu -->
            <b-menu class="is-size-6 mt-5" style="min-width: 200px;">
              <b-menu-list>
                <b-menu-item
                  :label="$t('pages.profile.title')"
                  @click="tabChange(0)"
                  active
                ></b-menu-item>
                <b-menu-item :label="$t('pages.profile.orders')">
                  <b-menu-item
                    :label="$t('pages.profile.buy')"
                    @click="setOrderType('buy')"
                  ></b-menu-item>
                  <b-menu-item
                    :label="$t('pages.profile.sell')"
                    @click="setOrderType('sell')"
                  ></b-menu-item>
                </b-menu-item>
                <b-menu-item
                  :label="$t('pages.profile.changePassword')"
                  @click="tabChange(2)"
                ></b-menu-item>
                <b-menu-item
                  :label="$t('pages.profile.deleteProfile')"
                  @click="deleteProfile"
                >
                </b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
        </div>
        <div class="column" ref="tab">
          <div v-show="tab === 0">
            <profile-section :user="user" />
          </div>
          <div v-show="tab === 1">
            <orders-section :orders="ordersOfType" :type="orderType" />
          </div>
          <div v-show="tab === 2">
            <password-change
              :processing.sync="loading"
              @changePassword="changePassword"
              ref="pass"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="container">
    <div class="is-size-4">{{ $t('errors.userNotFound') }}</div>
  </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProfileSection from '@/components/user/Profile.vue'
import PasswordChange from '@/components/user/PasswordChange.vue'
import OrdersSection from '@/components/user/Orders.vue'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import { scrollToPosition, scrollToElementAdjusted } from '@/utils/dom'

export default {
  name: 'ProfileEdit',
  components: {
    PasswordChange,
    ProfileSection,
    OrdersSection,
  },
  mixins: [VueBreakpointMixin],
  data() {
    return {
      tab: 0,
      loading: false,
      orderType: 'buy',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      orders: 'orders/orders',
    }),
    isBrowser() {
      let isBrowser = true
      if (global.device && global.device.platform) {
        isBrowser = global.device.platform === 'browser'
      }
      return isBrowser
    },
    ordersOfType() {
      return this.orders.filter(order => order.orderType === this.orderType)
    },
  },
  async created() {
    try {
      await Promise.all([this.getProfile(), this.getOrders()])
    } catch (error) {
      this.$buefy.toast.open({
        duration: 3000,
        message:
          error?.response?.data?.message || this.$t('errors.userNotFound'),
        position: 'is-bottom',
        type: 'is-danger',
      })
    }
  },
  methods: {
    ...mapActions({
      getProfile: 'user/getProfile',
      editProfile: 'user/editProfile',
      deleteUser: 'user/deleteProfile',
      getOrders: 'orders/fetchOrders',
      logout: 'auth/logout',
    }),
    setOrderType(type) {
      this.orderType = type
      this.tabChange(1)
    },
    async changePassword(password) {
      this.loading = true
      try {
        await this.editProfile({ password })
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('dialogs.messages.passwordChanged'),
          position: 'is-bottom',
          type: 'is-primary',
        })
        this.loading = false
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message:
            error?.response?.data?.message || this.$t('errors.somethingWrong'),
          position: 'is-bottom',
          type: 'is-danger',
        })
        this.loading = false
      }
    },
    async deleteProfile() {
      this.$buefy.dialog.confirm({
        title: this.$t('pages.profile.deleteProfile'),
        message: this.$t('dialogs.messages.sureToDeleteProfile'),
        cancelText: this.$t('buttons.cancel'),
        confirmText: this.$t('buttons.ok'),
        onConfirm: async () => {
          try {
            await this.deleteUser()
            this.logout()
            this.$router.replace('/')
          } catch (error) {
            this.$buefy.toast.open({
              duration: 3000,
              message:
                error?.response?.data?.message ||
                this.$t('errors.somethingWrong'),
              position: 'is-bottom',
              type: 'is-danger',
            })
          }
        },
        onCancel: () => {},
      })
    },
    handleVerify() {
      if (this.isBrowser) {
        this.$router.push('/account/verify/web')
      } else {
        this.$router.push('/account/verify/mobile')
      }
    },
    tabChange(tab) {
      this.tab = tab
      if (tab === 2) this.$refs.pass.resetForm()
      if (this.isMobile) {
        scrollToElementAdjusted(this.$refs.tab, 50)
      } else {
        scrollToPosition()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-list ::v-deep a.is-active {
  background-color: inherit;
  font-weight: bold;
}
</style>
