export const scrollToPosition = (top = 0, behavior = 'smooth') => {
  window.scrollTo({
    top,
    behavior,
  })
}

export const scrollToElement = (
  element,
  block = 'start',
  behavior = 'smooth'
) => {
  if (element) {
    element.scrollIntoView({ block, behavior })
  }
}

export const scrollToElementAdjusted = (
  element,
  offset,
  behavior = 'smooth'
) => {
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.scrollY - offset
  scrollToPosition(offsetPosition, behavior)
}
