<template>
  <div class="section">
    <b-breadcrumb separator="has-succeeds-separator">
      <b-breadcrumb-item class="is-size-5 mb-5" active
        ><span class="is-underlined">{{
          $t('pages.profile.changePassword')
        }}</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <ValidationObserver v-slot="{ passes, valid }" tag="div" ref="observer">
      <form @submit.prevent>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|min:6"
          :name="$t('fields.password')"
          vid="confirm"
          tag="div"
          class="mb-5"
        >
          <b-field
            :label="$t('fields.password')"
            :type="{ 'is-danger': errors[0] }"
            :message="errors[0] || '-'"
          >
            <b-input
              v-model="password"
              type="password"
              password-reveal
              :placeholder="$t('fields.password')"
            >
            </b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{
            required: true,
            confirmed: 'confirm',
          }"
          :name="$t('fields.passwordRepeat')"
          tag="div"
          class="mb-5"
        >
          <b-field
            :label="$t('fields.passwordRepeat')"
            :type="{ 'is-danger': errors[0] }"
            :message="errors[0] || '-'"
          >
            <b-input
              v-model="repeatPassword"
              type="password"
              password-reveal
              :placeholder="$t('fields.passwordRepeat')"
            >
            </b-input>
          </b-field>
        </ValidationProvider>
        <div class="is-flex is-justify-content-end">
          <b-button
            native-type="submit"
            type="is-primary"
            :loading="processing"
            :disabled="!valid"
            :expanded="isMobile"
            @click.prevent="passes(changePassword)"
            >{{ $t('buttons.changePassword') }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  name: 'PasswordChange',
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [VueBreakpointMixin],
  data() {
    return {
      password: null,
      repeatPassword: null,
    }
  },
  methods: {
    async changePassword() {
      this.$buefy.dialog.confirm({
        title: this.$t('pages.profile.changePassword'),
        message: this.$t('dialogs.messages.sureToChangePassword'),
        cancelText: this.$t('buttons.cancel'),
        confirmText: this.$t('buttons.ok'),
        onConfirm: async () => {
          this.$emit('changePassword', this.password)
          this.resetForm()
        },
        onCancel: () => {
          this.resetForm()
        },
      })
    },
    resetForm() {
      this.password = null
      this.repeatPassword = null
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.field ::v-deep .help:not(.is-danger) {
  height: 18px;
  visibility: hidden;
}

.field ::v-deep .help.is-danger {
  height: 18px;
  animation: reveal 1s;
}

@keyframes reveal {
  from {
    transform: scale(0);
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
