<template>
  <div class="section">
    <b-breadcrumb separator="has-succeeds-separator">
      <b-breadcrumb-item class="is-size-5 mb-5" active
        ><span class="is-underlined">{{
          $t('pages.profile.orders')
        }}</span></b-breadcrumb-item
      >
      <b-breadcrumb-item class="is-size-5 mb-5" active
        ><span class="is-underlined">{{
          $t(`pages.profile.${type}`)
        }}</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <!-- <div class="is-size-3 mb-5 is-underlined">
      {{ $t('pages.profile.orders') }}
    </div> -->
    <b-table
      v-if="orders && orders.length"
      :data="orders"
      :hoverable="true"
      :mobile-cards="true"
      :paginated="true"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-rounded="true"
      pagination-order="is-centered"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      class="orders-table"
    >
      <!-- GIVEN -->
      <!-- currency -->
      <b-table-column
        :label="isMobile ? $t('labels.currencyGiven') : $t('labels.given')"
        v-slot="props"
      >
        <div class="is-flex is-justify-content-flex-start">
          <crypto-logo
            :ticker="props.row.currencyGiven"
            :name="props.row.currencyReceived"
            :url="props.row.logoURL"
            :width="'1.5rem'"
            :minWidth="'1.5rem'"
          >
          </crypto-logo>
          <span class="has-text-weight-bold mr-4">{{
            props.row.currencyGiven
          }}</span>
        </div>
      </b-table-column>
      <!-- amount -->
      <b-table-column
        :label="isMobile ? $t('labels.amountGiven') : $t('labels.amount')"
        v-slot="props"
      >
        <span>{{ getRoundedTwoDecimals(props.row.amountGiven) }}</span>
      </b-table-column>
      <!-- address -->
      <b-table-column
        v-show="type === 'buy'"
        :label="isMobile ? $t('labels.addressGiven') : $t('fields.address')"
        v-slot="props"
      >
        <span>{{ props.row.addressGiven }}</span>
      </b-table-column>

      <!-- RECEIVED -->
      <!-- currency -->
      <b-table-column
        :label="
          isMobile ? $t('labels.currencyReceived') : $t('labels.received')
        "
        v-slot="props"
      >
        <div class="is-flex is-justify-content-flex-start">
          <crypto-logo
            :ticker="props.row.currencyReceived"
            :name="props.row.currencyReceived"
            :url="props.row.logoURL"
            :width="'1.5rem'"
            :minWidth="'1.5rem'"
          >
          </crypto-logo>
          <span class="has-text-weight-bold mr-4">{{
            props.row.currencyReceived
          }}</span>
        </div>
      </b-table-column>
      <!-- amount -->
      <b-table-column
        :label="isMobile ? $t('labels.amountReceived') : $t('labels.amount')"
        v-slot="props"
      >
        <span>{{ getRoundedTwoDecimals(props.row.amountReceived) }}</span>
      </b-table-column>
      <!-- address -->
      <b-table-column
        v-show="type === 'sell'"
        :label="isMobile ? $t('labels.addressReceived') : $t('fields.address')"
        v-slot="props"
      >
        <span>{{ props.row.addressReceived }}</span>
      </b-table-column>

      <!-- created at -->
      <b-table-column
        :label="$t('labels.createdDate')"
        width="200px"
        numeric
        v-slot="props"
      >
        <span>{{
          $dayjs(props.row.createdAt).format('DD/MM/YYYY HH:mm')
        }}</span>
      </b-table-column>

      <!-- udated at -->
      <b-table-column
        :label="$t('labels.updatedDate')"
        width="200px"
        numeric
        v-slot="props"
      >
        <span>{{
          $dayjs(props.row.updatedAt).format('DD/MM/YYYY HH:mm')
        }}</span>
      </b-table-column>

      <!-- satus -->
      <b-table-column :label="$t('labels.status')" width="50px" v-slot="props">
        <span>{{ props.row.status }}</span>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import numbersMixin from '@/mixins/numbers-mixin'
import CryptoLogo from '@/components/common/CryptoLogo'

export default {
  name: 'Orders',
  components: {
    CryptoLogo,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    orders: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [VueBreakpointMixin, numbersMixin],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
    }
  },
}
</script>

<style lang="scss" scoped>
.orders-table ::v-deep .pagination-link.is-current {
  background-color: $primary;
  border-color: $primary;
  color: black;
}
</style>
